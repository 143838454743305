export default class LineItem {
    type : string;
    dryratio : number;
    weight : number;
    comment : string;
    constructor(type:string = null, weight:number = 0, ratio:number = null, comment:string = "") {
        this.type = type;
        this.weight = weight;
        this.dryratio = ratio;
        this.comment =  comment;
    };

    dry() {
        return this.weight * this.dryratio;
    };

    wet() {
        return this.weight * (1 - this.dryratio);
    };
}