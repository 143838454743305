import Component, { tracked } from '@glimmer/component';
import LineItem from '../../../../utils/line_item';

export default class Recipe extends Component {
    @tracked items : Array<LineItem> = [
        new LineItem("Flour", 300, 1, "Full Wheat"),
        new LineItem("Water", 200, 0, ""),
        new LineItem("Starter (100%)", 100, 0.5, "Fresh 1:x:x-ratio sourdough starter"),
        new LineItem("Salt", 6, 1, "")
    ];

    constructor(args) {
        super(args);
    }

    delete_item(idx:number) {
        if (idx == 0) {
            this.items = this.items.slice(1);
        } else if (idx < this.items.length - 1) {
            this.items = this.items.slice(0, idx).concat(
                this.items.slice(idx+1)
            );
        } else {
            this.items = this.items.slice(0, -1);
        }
    }

    plan_this_bread() {
        this.args.onuse(
            Math.round(this.weight * (100 - this.salt) / 100),
            Math.round(this.hydration),
            Math.round(this.starter)
        );
    }

    add_item(newItem) {
        this.items = [...this.items, newItem];
    }

    @tracked('items')
    get weight() {
        let sum : number = 0;
        this.items.forEach(element => {
            sum += element.weight;
        });
        return sum;
    }

    @tracked('items')
    get dry() {
        let sum : number = 0;
        this.items.forEach(element => {
            sum += element.dry();
        });
        return sum;
    }

    @tracked('items')
    get wet() {
        let sum : number = 0;
        this.items.forEach(element => {
            sum += element.wet();
        });
        return sum;
    };

    @tracked('items')
    get hydration() {
        return this.wet / this.dry * 100;
    }

    @tracked('items')
    get starter() {
        let sum : number  = 0;
        this.items.forEach(element => {
            if (element.dryratio > 0 && element.dryratio < 1) {
                sum += element.weight;
            }
        });
        return sum / this.weight * 100;
    }

    @tracked('items')
    get salt() {
        let sum : number  = 0;
        this.items.forEach(element => {
            if (element.type == "Salt") {
                sum += element.weight;
            }
        });
        return sum / this.dry * 100;
    }
}
