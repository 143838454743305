import Component, { tracked } from '@glimmer/component';

export default class Calculator extends Component {
    @tracked weight: Number;
    @tracked hydration: Number;
    @tracked saltratio: Number;
    @tracked starter: Number;

    constructor(options) {
        super(options);
        this.weight = this.args.weight;
        this.hydration = this.args.hydration;
        this.starter = this.args.starter;
        this.saltratio = 1.8;
    }

    setweight(value) {
        this.weight = value;
    }

    sethydration(value) {
        this.hydration = value;
    }

    setstarter(value) {
        this.starter = value;
    }

    @tracked('hydration', 'weight')
    get allflour() {
        return Number(this.weight) * 100 / (100 + Number(this.hydration));
    }

    @tracked('hydration', 'weight', 'starter')
    get flour() {
        return this.allflour - this.starterweight / 2;
    }

    @tracked('hydration', 'weight', 'starter')
    get water() {
        return Number(this.weight) - this.allflour - this.starterweight / 2;
    }

    @tracked('weight', 'starter')
    get starterweight() {
        return Number(this.weight) * Number(this.starter) / 100;
    }

    @tracked('weight', 'starter')
    get startersize() {
        return this.starterweight * 100 / this.allflour;
    }

    @tracked('hydration', 'weight')
    get salt() {
        return Number(this.allflour) * Number(this.saltratio) / 100;
    }

    @tracked('weight', 'hydration')
    get result() {
        // result - (wet content * 0.3)
        return 0.3 * this.allflour + 0.7 * Number(this.weight);
    }
}
