import Component, { tracked } from '@glimmer/component';
import LineItem from '../../../../../utils/line_item';

export default class NewItem extends Component {
    types = [
        {name: "Flour", dryratio: 1.0},
        {name: "Water", dryratio: 0.0},
        {name: "Starter (100%)", dryratio: 0.5},
        {name: "Leaven (dry)", dryratio: 1.0},
        {name: "Salt", dryratio: 1.0},
    ];

    @tracked type: number;
    @tracked weight: number;
    @tracked comment: string;

    constructor(args) {
        super(args);
        this.reset_form();
    }

    reset_form() {
        let item = <HTMLSelectElement>document.getElementById('new_item_selection');
        if (item !== null) {
            item.selectedIndex = 0;
        }
        this.type = 0;
        this.weight = 0;
        this.comment = '';
    }

    set_type(event) {
        this.type = event.target.selectedIndex;
    }

    set_weight(event) {
        this.weight = Number(event.target.value);
    }

    set_comment(event) {
        this.comment = event.target.value;
    }

    add() {
        let item = new LineItem(
            this.types[this.type].name,
            this.weight,
            this.types[this.type].dryratio,
            this.comment
        );
        this.reset_form();
        this.args.add(item);
    }
}