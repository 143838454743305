import Component, { tracked } from '@glimmer/component';

export default class Sourdoughplanner extends Component {
    @tracked recipemode = false;
    @tracked weight = 750;
    @tracked hydration = 65;
    @tracked starter = 16;

    switch_recipe() {
        this.recipemode = !this.recipemode;
    }

    plan_this_bread(weight, hydration, starter) {
        this.weight = weight;
        this.hydration = hydration;
        this.starter = starter;
        this.recipemode = false;
    }
}
