import { default as __ui_components_Sourdoughplanner_Calculator_InputSlider_component__ } from '../src/ui/components/Sourdoughplanner/Calculator/InputSlider/component';
import { default as __ui_components_Sourdoughplanner_Calculator_InputSlider_template__ } from '../src/ui/components/Sourdoughplanner/Calculator/InputSlider/template';
import { default as __ui_components_Sourdoughplanner_Calculator_component__ } from '../src/ui/components/Sourdoughplanner/Calculator/component';
import { default as __ui_components_Sourdoughplanner_Calculator_template__ } from '../src/ui/components/Sourdoughplanner/Calculator/template';
import { default as __ui_components_Sourdoughplanner_Recipe_NewItem_component__ } from '../src/ui/components/Sourdoughplanner/Recipe/NewItem/component';
import { default as __ui_components_Sourdoughplanner_Recipe_NewItem_template__ } from '../src/ui/components/Sourdoughplanner/Recipe/NewItem/template';
import { default as __ui_components_Sourdoughplanner_Recipe_component__ } from '../src/ui/components/Sourdoughplanner/Recipe/component';
import { default as __ui_components_Sourdoughplanner_Recipe_template__ } from '../src/ui/components/Sourdoughplanner/Recipe/template';
import { default as __ui_components_Sourdoughplanner_component__ } from '../src/ui/components/Sourdoughplanner/component';
import { default as __ui_components_Sourdoughplanner_template__ } from '../src/ui/components/Sourdoughplanner/template';
import { default as __ui_components_round_helper__ } from '../src/ui/components/round/helper';
export default {'component:/sourdoughplanner/components/Sourdoughplanner/Calculator/InputSlider': __ui_components_Sourdoughplanner_Calculator_InputSlider_component__,'template:/sourdoughplanner/components/Sourdoughplanner/Calculator/InputSlider': __ui_components_Sourdoughplanner_Calculator_InputSlider_template__,'component:/sourdoughplanner/components/Sourdoughplanner/Calculator': __ui_components_Sourdoughplanner_Calculator_component__,'template:/sourdoughplanner/components/Sourdoughplanner/Calculator': __ui_components_Sourdoughplanner_Calculator_template__,'component:/sourdoughplanner/components/Sourdoughplanner/Recipe/NewItem': __ui_components_Sourdoughplanner_Recipe_NewItem_component__,'template:/sourdoughplanner/components/Sourdoughplanner/Recipe/NewItem': __ui_components_Sourdoughplanner_Recipe_NewItem_template__,'component:/sourdoughplanner/components/Sourdoughplanner/Recipe': __ui_components_Sourdoughplanner_Recipe_component__,'template:/sourdoughplanner/components/Sourdoughplanner/Recipe': __ui_components_Sourdoughplanner_Recipe_template__,'component:/sourdoughplanner/components/Sourdoughplanner': __ui_components_Sourdoughplanner_component__,'template:/sourdoughplanner/components/Sourdoughplanner': __ui_components_Sourdoughplanner_template__,'helper:/sourdoughplanner/components/round': __ui_components_round_helper__};
