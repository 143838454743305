import Component, { tracked } from '@glimmer/component';

export default class InputSlider extends Component {
  @tracked val: number;

  constructor(options) {
    super(options);
    this.val = this.args.val;
  }

  setval(event) {
    this.val = Number(event.target.value);
  }

  incr(event) {
    this.val += 1;
    this.args.onchange(this.val);
  }
  decr(event) {
    this.val -= 1;
    this.args.onchange(this.val);
  }
}
